import React from "react";
import { useTranslation } from "react-i18next";

import "../../styles/webflow.css";

const Newsletter = () => {
  const { t } = useTranslation();


  return (
    <div class="section wf-section" style={{ marginBottom: "100px", background: "black"}}>
    <div class="container">
      <div class="newsletter-wrapper">
        <div class="vertical-container">
          <div class="horizontal-container">
            <img
              src="https://res.cloudinary.com/avomeraydin/image/upload/v1681210875/Frame_11_ktz1o7.png"
              loading="lazy"
              alt=""
              style={{ width: "180px", marginLeft: "100px" }}
            />
          </div>
        </div>
        <div class="vertical-container" style={{ marginTop: "60px" }}>
          <div class="">
          <div>
            <h3 class="h3">{t("doingBusinessGuide.heading")}</h3>
            <p class="text" style={{ marginBottom: "20px" }}>
            {t("doingBusinessGuide.description")}
            </p>
            <a href={t("doingBusinessGuide.link")} style={{color: "white", width: "100px", background: "black"}} class="submit w-button" target="_blank">
            {t("doingBusinessGuide.cta")}
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Newsletter;
