/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { TextAlignJustified, Cross } from "akar-icons";
import { useTranslation } from "react-i18next";

import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import config from "../../config";
import "../../styles/webflow.css";
import logo from "../../styles/turkeyfor-svg.svg";

const { logoBlue } = config;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const Menu = () => {
  const [activeDropdown, setActiveDropdown] = useState("none");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size.width]);

  const setDropdown = (activeDropdownValue) => {
    setActiveDropdown(activeDropdownValue);
  };

  const setHamburgerStatus = (hamburgerStatus) => {
    setHamburgerOpen(hamburgerStatus);
  };

  const handleLinkClick = () => {
    setDropdown("remote");
    if (isMobile) {
      setHamburgerStatus(false);
    }
    document.body.style.overflow = "auto";
  };

  return (
    <header>
      <div
        data-animation="over-left"
        data-collapse="all"
        data-duration="600"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        class="navigation w-nav"
      >
        <div class="navbar-container">
          <div class="horizontal-container">
            <div
              class="menu-button w-nav-button"
              aria-label="menu"
              role="button"
              tabindex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div class="burger-icon">
                <div class="top-burger-line"></div>
                <div class="middle-burger-line"></div>
                <div class="bottom-burger-line"></div>
              </div>
            </div>
            <a
              href="/"
              aria-current="page"
              class="w-nav-brand w--current"
              aria-label="home"
            >
              <img
                src="https://res.cloudinary.com/avomeraydin/image/upload/v1688808484/turkeyfor-svg_phuqsd.svg"
                loading="lazy"
                alt=""
                style={{ width: "180px" }}
              />
            </a>
          </div>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <form action="/search" class="search menu w-form">
              <input
                type="submit"
                value=""
                class="search-button menu w-button"
              />
              <input
                type="search"
                className="search-input menu w-input"
                maxlength="256"
                name="query"
                placeholder="Search"
                id="search"
                required=""
              />
            </form>
            <div class="horizontal-line"></div>
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <a href="/" class="nav-link w-inline-block">
                    <div>{t("menu.home")}</div>
                  </a>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <a href="/contact" class="nav-link w-inline-block">
                    <div>{t("menu.contact")}</div>
                  </a>
                  <a
                    href="/blog"
                    class="nav-link w-inline-block"
                    style={{ marginLeft: 5 }}
                  >
                    <div>Blog</div>
                  </a>
                </div>
              </div>
            </div>
            <div class="horizontal-line"></div>
          </nav>
          <div class="horizontal-container">
            <a
              href="/contact"
              style={{ color: "white", marginRight: 10 }}
              class="primary-button w-button"
            >
              {t("menu.contact")}
            </a>
            <a
              href="/blog"
              style={{ color: "white" }}
              class="primary-button w-button"
            >
              Blog
            </a>
          </div>
        </div>
        <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
      </div>

      <script
        src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6246f628ff60cf28f937a441"
        type="text/javascript"
        integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
        crossorigin="anonymous"
      ></script>
      <script
        src="https://assets.website-files.com/6246f628ff60cf28f937a441/js/webflow.a3ec327ce.js"
        type="text/javascript"
      ></script>
    </header>
  );
};

const Hamburger = (props) => {
  const { children, hamburgerOpen, setHamburgerStatus } = props;
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setHamburgerStatus(true);
    } else {
      setHamburgerStatus(false);
      document.body.style.overflow = "auto";
    }
  }, [isMobile]);

  return (
    <div role="button" tabIndex={0}>
      {isMobile && (
        <Link
          onClick={() => {
            if (hamburgerOpen) {
              setHamburgerStatus(false);
              document.body.style.overflow = "auto";
            } else {
              setHamburgerStatus(true);
              document.body.style.overflow = "hidden";
            }
          }}
        >
          {hamburgerOpen ? (
            <Cross strokeWidth={2} size={28} style={{ margin: "0.5rem" }} />
          ) : (
            <TextAlignJustified
              strokeWidth={2}
              size={28}
              style={{ margin: "0.5rem 0 0.5rem 0.7rem" }}
            />
          )}
        </Link>
      )}
      <div
        style={{
          display: hamburgerOpen ? "block" : "none",
          margin: "none",
          padding: "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
