import React from "react";

import Hero from "../components/Hero/Hero.jsx";
import Newsletter from "../components/Newsletter/Newsletter.jsx";
import Guide from "../components/Guide/Guide.jsx";
import ContentList from "../components/ContentList/";

export default function index() {
  return (
    <div>
      <Hero />
      <Guide />
      <ContentList />
      <Newsletter />
    </div>
  );
}
