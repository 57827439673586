import React from "react";
import { useTranslation } from "react-i18next";

import "../../styles/webflow.css";
import "./guide.css"

const Newsletter = () => {
  const { t } = useTranslation();


  return (
    <div class="section wf-section go-to-top" style={{ marginBottom: "100px", background: "black"}}>
    <div class="container">
      <div class="newsletter-wrapper">
        <div class="vertical-container">
          <div class="horizontal-container">
            <img
              src="https://res.cloudinary.com/avomeraydin/image/upload/v1685569103/by/img/E-Book-Reader-Mockup-2-Avelina-Studio_yl3ofe.png"
              loading="lazy"
              alt=""
              style={{ width: "250px", marginLeft: "100px" }}
            />
          </div>
        </div>
        <div class="vertical-container" style={{ marginTop: "60px" }}>
          <div class="">
          <div>
            <h3 class="h3">{t("realEstateGuide.heading")}</h3>
            <p class="text" style={{ marginBottom: "20px" }}>
            {t("realEstateGuide.description")}
            </p>
            <a href={t("realEstateGuide.link")} style={{color: "white", width: "100px", background: "black"}} class="submit w-button" target="_blank">
            {t("realEstateGuide.cta")}
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Newsletter;
