import React from "react";
import PropTypes from "prop-types";
import "./hero.scss";
import blog from "../../../data/data-blog.json";

const elementStyle = {
  transform:
    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
  transformStyle: "preserve-3d",
};

// This is for the sorting of the blog posts
let publishedBlog = blog.filter((item) => item.published === true);
const lastBlog = publishedBlog.sort((a, b) => a.id - b.id);

const Hero = () => (
  <div class="section wf-section">
    <div class="container">
      <div
        data-w-id="6b615ad4-7476-2294-dea1-280307e5d98f"
        style={{ opacity: "1" }}
        class="hero-grid"
      >
        <div
          id="w-node-_4a6487b9-33ba-98f1-d6e3-1deb4f19ce08-7537a443"
          class="w-dyn-list"
        >
          <div role="list" class="w-dyn-items">
            <div role="listitem" class="w-dyn-item">
              <a
                id="w-node-_6fc717ff-45fc-ba81-10be-d606d879929f-7537a443"
                href={lastBlog[lastBlog.length - 1].slug}
                class="post-grid w-inline-block"
              >
                <div
                  id="w-node-d1db1441-0d02-8e66-e29d-729e10d10930-7537a443"
                  class="image-post"
                >
                  <div class="horizontal-container">
                    <div class="category">
                      {lastBlog[lastBlog.length - 1].category}
                    </div>
                    <div class="small-text date">
                      {lastBlog[lastBlog.length - 1].date}
                    </div>
                  </div>
                  <div class="overlay"></div>
                  <img
                    src={lastBlog[lastBlog.length - 1].image + `herobig`}
                    loading="lazy"
                    alt=""
                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 46vw, (max-width: 1919px) 37vw, 608px"
                    class="image absolute"
                  />
                </div>
                <h1
                  id="w-node-_520e306c-e293-134b-9215-9dd2af220cf1-7537a443"
                  class="h2 titlecase"
                >
                  {lastBlog[lastBlog.length - 1].title}
                </h1>
                <p
                  id="w-node-_85c0e8f9-9e2b-7d13-39b0-35178075f382-7537a443"
                  class="text"
                >
                  {lastBlog[lastBlog.length - 1].excerpt
                    ? `${lastBlog[lastBlog.length - 1].excerpt
                        .split(" ")
                        .slice(0, 20)
                        .join(" ")}${
                        lastBlog[lastBlog.length - 1].excerpt.split(" ")
                          .length > 20
                          ? "..."
                          : ""
                      }`
                    : ""}
                </p>
                <div
                  id="w-node-_1ad544d5-4b3e-5c20-3dbf-b32e132c4953-7537a443"
                  class="horizontal-container"
                >
                  <div
                    id="w-node-_9d73e3d8-7860-4786-dd08-7821ed5b5a1d-7537a443"
                    class="small-text"
                  >
                    By
                  </div>
                  <div
                    id="w-node-_368e7097-e2fb-b241-c7fb-87ce5efa8e3a-7537a443"
                    class="small-text author"
                  >
                    {lastBlog[lastBlog.length - 1].author === 1
                      ? "Nevzat Ogulcan Bayraktar (Attorney at Law)"
                      : "Turkey Foreigners Team"}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          id="w-node-_8b7308d4-0809-56af-7322-b4ed712cffb0-7537a443"
          class="w-dyn-list"
        >
          <div role="list" class="w-dyn-items">
            <div role="listitem" class="w-dyn-item">
              <a
                href={lastBlog[lastBlog.length - 2].slug}
                class="post-grid small w-inline-block"
              >
                <div
                  id="w-node-be306b0c-9e99-c45c-2953-6b93cfd9faa7-7537a443"
                  class="image-post small"
                >
                  <div class="horizontal-container">
                    <div class="category">
                      {lastBlog[lastBlog.length - 2].category ===
                      "\n\nSilikon Vadisini Anlamak"
                        ? "Vadiyi Anlamak"
                        : lastBlog[lastBlog.length - 2].category}
                    </div>
                    <div class="small-text date">
                      {lastBlog[lastBlog.length - 2].date}
                    </div>
                  </div>
                  <div class="overlay"></div>
                  <img
                    src={lastBlog[lastBlog.length - 2].image + `mid`}
                    loading="lazy"
                    alt=""
                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 89.4453125px, (max-width: 1919px) 22vw, 364.796875px"
                    class="image absolute"
                  />
                </div>
                <h2
                  id="w-node-_50773d0d-8e59-1b8b-1534-1d8f019151cb-7537a443"
                  class="h4 titlecase"
                >
                  {lastBlog[lastBlog.length - 2].title}
                </h2>
                <div
                  id="w-node-e8853cd6-5f45-9913-d572-77136f3cc57e-7537a443"
                  class="horizontal-container"
                >
                  <div class="small-text">By</div>
                  <div class="small-text author">
                    {lastBlog[lastBlog.length - 2].author === 1
                      ? "Nevzat Ogulcan Bayraktar (Attorney at Law)"
                      : "Turkey Foreigners Team"}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          id="w-node-_53d48955-dff2-7697-71b1-cd9e0602b0c1-7537a443"
          class="w-dyn-list"
        >
          <div role="list" class="w-dyn-items">
            <div role="listitem" class="w-dyn-item">
              <a
                href={lastBlog[lastBlog.length - 3].slug}
                class="post-grid small w-inline-block"
              >
                <div
                  id="w-node-a0891dcb-7760-b500-0210-c867f7ec70cf-7537a443"
                  class="image-post small"
                >
                  <div class="horizontal-container">
                    <div class="category">
                      {lastBlog[lastBlog.length - 3].category ===
                      "\n\nSilikon Vadisini Anlamak"
                        ? "Vadiyi Anlamak"
                        : lastBlog[lastBlog.length - 1].category}
                    </div>
                    <div class="small-text date">
                      {lastBlog[lastBlog.length - 3].date}
                    </div>
                  </div>
                  <div class="overlay"></div>
                  <img
                    src={lastBlog[lastBlog.length - 3].image + `mid`}
                    loading="lazy"
                    alt=""
                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 41vw, (max-width: 1919px) 92.9140625px, 105.359375px"
                    class="image absolute"
                  />
                </div>
                <h2
                  id="w-node-_2c920b29-7390-6e67-f395-59ae823dcba6-7537a443"
                  class="h4 titlecase"
                >
                  {lastBlog[lastBlog.length - 3].title}
                </h2>
                <div
                  id="w-node-_498e9766-66fc-668d-0ac1-3c301998b624-7537a443"
                  class="horizontal-container"
                >
                  <div class="small-text">By</div>
                  <div class="small-text author">
                    {lastBlog[lastBlog.length - 3].author === 1
                      ? "Nevzat Ogulcan Bayraktar (Attorney at Law)"
                      : "Turkey Foreigners Team"}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          id="w-node-_2f266308-f1b0-0477-4492-221f34bd2801-7537a443"
          class="vertical-line"
        ></div>
        <div
          id="w-node-_7461e468-aaf3-8ecc-9640-978649a2ed63-7537a443"
          class="category-hero"
        >
          <div class="w-dyn-list">
            <div role="list" class="w-dyn-items">
              <div
                role="listitem"
                class="category-header bottom-margin w-dyn-item"
              >
                <h4 class="h3">Life in Turkey</h4>
              </div>
            </div>
          </div>
          <div class="w-dyn-list">
            <div role="list" class="w-dyn-items">
              <div role="listitem" class="w-dyn-item">
                <a
                  data-w-id="72a203d7-55a5-7406-a8e1-bdea3fa1693d"
                  href={lastBlog[lastBlog.length - 4].slug}
                  class="small-article bottom-line w-inline-block"
                >
                  <div class="small-wrapper">
                    <img
                      src={lastBlog[lastBlog.length - 4].image + `small`}
                      loading="lazy"
                      style={elementStyle}
                      alt=""
                      sizes="(max-width: 479px) 35vw, (max-width: 767px) 90px, (max-width: 991px) 52.0234375px, (max-width: 1919px) 90px, 150px"
                      class="image absolute"
                    />
                  </div>
                  <h3 class="h4 titlecase">
                    {lastBlog[lastBlog.length - 4].title}
                  </h3>
                </a>
              </div>
              <div role="listitem" class="w-dyn-item">
                <a
                  data-w-id="72a203d7-55a5-7406-a8e1-bdea3fa1693d"
                  href={lastBlog[lastBlog.length - 5].slug}
                  class="small-article bottom-line w-inline-block"
                >
                  <div class="small-wrapper">
                    <img
                      src={lastBlog[lastBlog.length - 5].image + `small`}
                      loading="lazy"
                      style={elementStyle}
                      alt=""
                      sizes="(max-width: 479px) 35vw, (max-width: 767px) 90px, (max-width: 991px) 52.0234375px, (max-width: 1919px) 90px, 150px"
                      class="image absolute"
                    />
                  </div>
                  <h3 class="h4 titlecase">
                    {lastBlog[lastBlog.length - 5].title}
                  </h3>
                </a>
              </div>
              <div role="listitem" class="w-dyn-item">
                <a
                  data-w-id="72a203d7-55a5-7406-a8e1-bdea3fa1693d"
                  href={lastBlog[lastBlog.length - 6].slug}
                  class="small-article bottom-line w-inline-block"
                >
                  <div class="small-wrapper">
                    <img
                      src={lastBlog[lastBlog.length - 6].image + `small`}
                      loading="lazy"
                      style={elementStyle}
                      alt=""
                      sizes="(max-width: 479px) 35vw, (max-width: 767px) 90px, (max-width: 991px) 52.0234375px, (max-width: 1919px) 90px, 150px"
                      class="image absolute"
                    />
                  </div>
                  <h3 class="h4 titlecase">
                    {lastBlog[lastBlog.length - 6].title}
                  </h3>
                </a>
              </div>
              <div role="listitem" class="w-dyn-item">
                <a
                  data-w-id="72a203d7-55a5-7406-a8e1-bdea3fa1693d"
                  href={lastBlog[lastBlog.length - 7].slug}
                  class="small-article bottom-line w-inline-block"
                >
                  <div class="small-wrapper">
                    <img
                      src={lastBlog[lastBlog.length - 7].image + `small`}
                      loading="lazy"
                      style={elementStyle}
                      alt=""
                      sizes="(max-width: 479px) 35vw, (max-width: 767px) 90px, (max-width: 991px) 52.0234375px, (max-width: 1919px) 90px, 150px"
                      class="image absolute"
                    />
                  </div>
                  <h3 class="h4 titlecase">
                    {lastBlog[lastBlog.length - 7].title}
                  </h3>
                </a>
              </div>
              <div role="listitem" class="w-dyn-item">
                <a
                  data-w-id="72a203d7-55a5-7406-a8e1-bdea3fa1693d"
                  href={lastBlog[lastBlog.length - 8].slug}
                  class="small-article bottom-line w-inline-block"
                >
                  <div class="small-wrapper">
                    <img
                      src={lastBlog[lastBlog.length - 8].image + `small`}
                      loading="lazy"
                      style={elementStyle}
                      alt=""
                      sizes="(max-width: 479px) 35vw, (max-width: 767px) 90px, (max-width: 991px) 52.0234375px, (max-width: 1919px) 90px, 150px"
                      class="image absolute"
                    />
                  </div>
                  <h3 class="h4 titlecase">
                    {lastBlog[lastBlog.length - 8].title}
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Hero.defaultProps = {
  isNeedMainLabel: false,
  location: {},
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  isNeedMainLabel: PropTypes.bool,
};

export default Hero;
