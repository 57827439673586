/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from "react";
import SEO from "../components/SEO/SEO";
import blog from "../../data/data-blog.json";
import Guide from "../components/RealEstateGuide/RealEstateGuide.jsx";

const Template = ({ pageContext }) => {
  const { airtableData } = pageContext;
  let newUrl = airtableData.image.slice(3, -5);
  let blogwth = blog.filter((item) => item.id === airtableData.id);
  let blogwthlast = blogwth[0];

  return (
    <>
      {airtableData && (
        <SEO
          title={airtableData.title}
          description={airtableData.description}
        />
      )}
      <div class="section margin wf-section">
        <Guide />
        <div class="container">
          <div
            data-w-id="bff9d3c6-caa3-3796-e08d-a48fa1ce7e28"
            class="center-container bottom-margin"
          >
            <div class="horizontal-container">
              <div class="bold-text right-margin">-</div>
              <div class="bold-text">{blogwthlast.category}</div>
              <div class="bold-text left-margin">-</div>
            </div>
            <h1 class="h1 titlecase">{blogwthlast.title}</h1>
            <div class="horizontal-container">
              <div class="small-text">By</div>
              <div class="small-text author">
                {airtableData.author === 1
                  ? "Nevzat Ogulcan Bayraktar (Attorney at Law)"
                  : "Turkey Foreigners Team"}
              </div>
            </div>
          </div>
          <div class="main-image">
            <img
              data-w-id="59718b17-ca90-2e75-0d9f-d3af496ddee2"
              loading="lazy"
              src={newUrl + `public`}
              alt=""
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 90vw, (max-width: 1919px) 780px, 980px"
              class="image"
            />
          </div>
          <div class="rich-text w-richtext">
            <h1>{blogwthlast.title}</h1>
            <p>{blogwthlast.intro}</p>
            <h2>{blogwthlast.section1Headline}</h2>
            <p>{blogwthlast.section1Content}</p>
            <h2>{blogwthlast.section2Headline}</h2>
            <p>{blogwthlast.section2Content}</p>
            <h2>{blogwthlast.section3Headline}</h2>
            <p>{blogwthlast.section3Content}</p>
            <h2>{blogwthlast.section4Headline}</h2>
            <p>{blogwthlast.section4Content}</p>
            <h2>{blogwthlast.section5Headline}</h2>
            <p>{blogwthlast.section5Content}</p>
            <h2>{blogwthlast.section6Headline}</h2>
            <p>{blogwthlast.section6Content}</p>
            <h2>{blogwthlast.section7Headline}</h2>
            <p>{blogwthlast.section7Content}</p>
            <h2>{blogwthlast.section8Headline}</h2>
            <p>{blogwthlast.section8Content}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
