// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://www.turkeyforeigners.legal";
const config = {
  title: "Turkey Foreigners Legal",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "Turkey Foreigners",
  description: "Turkey Foreigners Legal and Life",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  image:
    "https://www.bayraktarattys.com/wp-content/uploads/2023/03/baylogo.jpg", // Path to your image you placed in the 'static' folder
  logoBlue:
    "https://www.bayraktarattys.com/wp-content/uploads/2023/03/baylogo.jpg",
  postThumbnail:
    "https://www.bayraktarattys.com/wp-content/uploads/2023/03/bayraktar-logo-yatay.jpg",
  twitterUsername: "",
  blogDesktopBanner:
    "https://www.bayraktarattys.com/wp-content/uploads/2023/03/bayraktar-logo-yatay.jpg",
  blogMobileBanner:
    "https://www.bayraktarattys.com/wp-content/uploads/2023/03/bayraktar-logo-yatay.jpg",
  airtableTables: ["Blog"],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: ["slug", "title", "description"],
};

module.exports = config;
